<template>
  <q-page class="q-pa-sm">
    <div class="row full-width">
      <q-table
          v-model:pagination="pagination"
          :columns="columns"
          :loading="loading"
          :rows="rows"
          :rows-per-page-options="[3, 10, 50, 100, 1000]"
          class="full-width"
          flat
          row-key="campaign_id"
          title=""
          @request="onRequest"
      >
        <template v-slot:top-left>
          <q-tabs
              v-model="statusFilter"
              align="justify"
              dense
          >
            <q-tab icon="done_all" label="Все" name="">
            </q-tab>
            <q-tab icon="online_prediction" label="Одобренные" name="approved">
            </q-tab>
            <q-tab icon="drive_file_rename_outline" label="Черновики" name="draft">
            </q-tab>
            <q-tab icon="archive" label="В архиве" name="archived">
            </q-tab>
          </q-tabs>
        </template>

        <template v-slot:top-right>
          <q-btn :to="$route.fullPath + '/addedit'" color="accent" icon="add"
                 label="Добавить"
                 size="sm"/>

          <q-btn class="q-mx-sm" color="secondary" icon="refresh"
                 label=""
                 size="sm"
                 @click="()=>onRequest()"/>
        </template>

        <template v-slot:body-cell-title="{row: entity}">
          <q-td class="text-left">
            <q-item style="width: 300px;">
              <q-item-section>
                <q-item-label class="text-subtitle2 text-weight-bold ellipsis">
                  {{ entity.header || 'Без названия' }}
                  <q-tooltip v-if="entity.header">{{ entity.header }}</q-tooltip>
                </q-item-label>

                <q-item-label caption class="ellipsis">
                  {{ entity.description || 'Нет описания' }}
                  <q-tooltip v-if="entity.description">{{ entity.description }}</q-tooltip>
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-td>
        </template>

        <template v-slot:body-cell-status="{row: entity}">
          <q-td class="text-center">
            <q-item>
              <q-item-section>
                <q-item-label>
                  <q-chip v-if="entity.campaign_status === 'approved'" class="q-ml-none"
                          color="green"
                          icon="online_prediction"
                          size="sm" text-color="white">
                    Одобрено
                  </q-chip>

                  <q-chip v-if="entity.campaign_status === 'draft'" class="q-ml-none" color="grey"
                          icon="drive_file_rename_outline"
                          size="sm" text-color="white">
                    Черновик
                  </q-chip>

                  <q-chip v-if="entity.campaign_status === 'archived'" class="q-ml-none"
                          color="grey-5"
                          icon="archive"
                          size="sm" text-color="white">
                    Архив
                  </q-chip>

                  <q-chip class="q-ml-none"
                          color="grey"
                          icon="add_circle_outline"
                          size="sm"
                          text-color="white">
                    {{
                      parseFloat(entity.price).toFixed(2)
                    }} ДР
                    <q-tooltip>Сумма</q-tooltip>
                  </q-chip>
                </q-item-label>

                <q-item-label caption>
                  <q-chip v-if="entity.used" class="q-ml-none" color="green"
                          icon="check_circle_outline"
                          size="sm" text-color="white">
                    {{ entity.used }}
                    <q-tooltip>Использовано</q-tooltip>
                  </q-chip>

                  <q-chip v-if="entity.total" color="grey" icon="pending" size="sm">
                    {{ entity.total }}
                    <q-tooltip>Всего</q-tooltip>
                  </q-chip>
                  <div v-else>
                    Нет кодов
                  </div>
                </q-item-label>

              </q-item-section>
            </q-item>
          </q-td>
        </template>

        <template v-slot:body-cell-action="{row: entity}">
          <q-td class="text-center">
            <q-btn
                v-if="entity.campaign_status === 'draft' || entity.campaign_status === 'approved'"
                :to="$route.fullPath + '/addedit/' + entity.campaign_id"
                class="q-ma-sm"
                dense
                icon="edit"
                round
                size="sm"
            >
              <q-tooltip>Редактировать</q-tooltip>
            </q-btn>

            <q-btn v-if="entity.campaign_status === 'draft'"
                   class="q-ma-sm"
                   color="negative"
                   icon="delete"
                   round
                   size="xs">
              <q-tooltip>Удалить</q-tooltip>
              <confirm-menu :ok="() => remove(entity.campaign_id)"/>
            </q-btn>

            <br>

            <q-btn v-if="entity.campaign_status === 'draft'"
                   class="q-ma-sm"
                   color="green"
                   dense
                   icon="published_with_changes"
                   round
                   size="sm"
            >
              <q-tooltip>Одобрить</q-tooltip>
              <confirm-menu :ok="() => updateStatus(entity.campaign_id, 'approved')"/>
            </q-btn>

            <q-btn v-if="entity.campaign_status === 'approved'"
                   class="q-ma-sm"
                   dense
                   icon="archive"
                   round
                   size="sm"
            >
              <q-tooltip>В архив</q-tooltip>
              <confirm-menu :ok="() => updateStatus(entity.campaign_id, 'archived')"/>
            </q-btn>

            <promocode-generator v-if="entity.campaign_status !== 'archived'"
                                 :model-value="entity"
                                 @created="() => onRequest()"/>

            <q-btn v-if="entity.download_url"
                   :href="entity.download_url"
                   class="q-ma-sm"
                   dense
                   icon="folder_zip"
                   round
                   size="sm"
                   target="_blank"
            >
              <q-tooltip>Скачать промокоды в zip-архиве</q-tooltip>
            </q-btn>
          </q-td>
        </template>

      </q-table>
    </div>
  </q-page>
</template>

<script>
import { MarketApi } from '@/api/apis/market-api.ts';
import ConfirmMenu from '@/components/forms/confirmMenu';
import PromocodeGenerator from '@/components/organizations/campaigns/promocodeGenerator';
import { toHumanDate } from '@/helpers/filters/toHumanDate';
import { Notify } from 'quasar';
import {
  defineComponent, onMounted, ref, watch,
} from 'vue';

const columns = [
  {
    name: 'title',
    label: '',
  },

  {
    name: 'status',
    label: '',
  },

  {
    name: 'campaign_start',
    field: 'campaign_start',
    align: 'center',
    label: 'Дата начала',
    format: toHumanDate,
  },
  {
    name: 'campaign_end',
    field: 'campaign_end',
    align: 'center',
    label: 'Дата окончания',
    format: toHumanDate,
  },
  {
    name: 'action',
    label: '',
  },
];

export default defineComponent({

  components: {
    ConfirmMenu,
    PromocodeGenerator,
  },

  setup() {
    const rows = ref([]);

    const statusFilter = ref('');
    const loading = ref(false);
    const pagination = ref({
      sortBy: 'updated_at',
      descending: true,
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 0,
    });

    async function onRequest(props) {
      props = props || {
        pagination: pagination.value,
      };

      const {
        page,
        rowsPerPage,
        sortBy,
        descending,
      } = props.pagination;

      // debugger;

      loading.value = true;

      const response = await new MarketApi().campaignControllerGetCampaignList(
        (page - 1) * rowsPerPage,
        rowsPerPage,
        undefined,
        undefined,
        undefined,
        statusFilter.value || undefined,
      );

      const { data } = response.data;
      pagination.value.rowsNumber = response.data.count;
      rows.value.splice(0, rows.value.length, ...data);

      pagination.value.page = page;
      pagination.value.rowsPerPage = rowsPerPage;
      pagination.value.sortBy = sortBy;
      pagination.value.descending = descending;

      loading.value = false;
    }

    onMounted(() => onRequest());
    watch(() => statusFilter.value, () => onRequest());

    return {
      statusFilter,
      loading,
      pagination,
      columns,
      rows,
      console,
      onRequest,

      async remove(id) {
        try {
          await new MarketApi().campaignControllerDeleteCampaign({
            campaign_id: id,
          });

          onRequest();

          Notify.create('Удалено');
        } catch (e) {
          console.error(e);
        }
      },

      async updateStatus(id, status) {
        try {
          await new MarketApi().campaignControllerUpdateCampaignStatus({
            campaign_id: id,
            campaign_status: status,
          });

          Notify.create('Сохранено');

          await onRequest();
        } catch (e) {
          console.error(e);
        }
      },
    };
  },
});
</script>

<style>

</style>
